import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { useCookies } from 'react-cookie';
import SEO from "../components/seo/seo"
import Layout from "../components/layout/layout"
import AppBar from "../components/appBar/appBar"
import MainSection from "../components/mainSection/mainSection"
import SecondarySection from "../components/secondarySection/secondarySection"
import PageSection from "../components/pageSection/pageSection"
import OptOut from "../components/optOut/optOut"
import IFrame from "../components/iFrame/iFrame"
import Modal from "../components/modal/modal"

import * as ACTIONS from "../state/actions"
import Card from "../components/card/card";

const tcPdf = <IFrame file="../../TC-Plataforma.pdf" title="Terms of use"/>;
const pcPdf = <IFrame file="../../PC.pdf" title="Privacy policy"/>;
// const footerDesc = "";
const contentMaxWidth = "980px";

const RemIdentity = () => {
    const appState = useSelector(state => state.appReducer);
    const dispatch = useDispatch();

    const [cookies, setCookie] = useCookies(['consent']);

    const launchFormHandler = () => {
        window.open("https://evvafinanzas.typeform.com/to/fmKiFH3T", "blank");
    };

    const termsUseHandler = () => {
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
        dispatch(ACTIONS.showModal({display: true, content: tcPdf }))
    };

    const privacyPolicyHandler = () => {
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
        dispatch(ACTIONS.showModal({display: true, content: pcPdf }))
    };

    const cookieHandler = () => {
        setCookie("consent", "dismiss", { path: '/' });
    };

    const closeModal = () => dispatch(ACTIONS.hideModal());

    return (
        <Layout lang={appState.lang} privacyPolicyAction={privacyPolicyHandler} termsUseHandlerAction={termsUseHandler}>
            <SEO lang={appState.lang} title="KYC & AML | Verify the identity of your customers online." description="Only legitimate customers. Verify the identity of your customers so you can prevent fraud attacks, comply with KYC and AML regulations."/>
            {appState.modal.display && <Modal content={appState.modal.content} closeModal={closeModal}/> }
            {!cookies.consent &&
            <OptOut text="This websites uses cookies to ensure that you get the best experience on our website." privacyPolicyAction={privacyPolicyHandler} privacyPolicyLabel="See Privacy Policy" cookieAction={cookieHandler}/>
            }
            <MainSection
                title="Verify your users identity with confidence."
                description="<h3>Only legitimate customers. Verify the identity of your customers so you can prevent fraud attacks, comply with KYC and AML regulations.</h3>"
                textColor="primary"
                position="left"
                image="hero-rem.png"
                actionLabel="Get in touch"
                action={launchFormHandler}
                amplitude={appState.amplitude}
                amplitudeClickEvent="ClickLearnEVVArem"
            >
                <AppBar pathname={appState.path}/>
            </MainSection>
            <PageSection
                title="New challenges in a digital world "
                maxColumns="4"
            >
                <Card
                    imageHeight="160px"
                    imageWidth="240px"
                    image="reduce-scammers-icon.png"
                    description="<p>Scammers and fraudsters in your business flow or platform. Guarantee legitimacy and confidence in your clients.</p>"
                    textAlign="center"
                    descriptionColor="#333A42"
                />
                <Card
                    imageHeight="160px"
                    imageWidth="240px"
                    image="help-team-risk-icon.png"
                    description="<p>Manual operation. Empower your risk team. It verifies the identity in a secure way, and determines if a user is legitimate or fraudulent.</p>"
                    textAlign="center"
                    descriptionColor="#333A42"
                />
                <Card
                    imageHeight="160px"
                    imageWidth="240px"
                    image="easy-prove-identity.png"
                    description="<p>Friction in your processes. Make it easy for your users to prove their identity and, at the same time, meet your compliance requirements.</p>"
                    textAlign="center"
                    descriptionColor="#333A42"
                />
                <Card
                    imageHeight="160px"
                    imageWidth="240px"
                    image="security-layer-icon.png"
                    description="<p>Fraud losses. Build and embed a layer of security before allowing high-risk features or operations if you observe suspicious activity.</p>"
                    textAlign="center"
                    descriptionColor="#333A42"
                />
            </PageSection>
            <PageSection title="Introducing REM digital identity" description="Based on Facetec technology, used to verify the digital identity of millions of global users, a fundamental part of your business process to know your customers (KYC), and reduce fraud in your operations." maxWidth={contentMaxWidth}>
                <Card
                    title="Biometrics"
                    description="<p>Match the photo of official IDs with selfies of the document holder, and protect your platform from fraudster with our biometric analysis of movement and faces.</p>"
                    descriptionColor="#363636"
                    image="rem-2.png"
                    imageWidth="260px"
                    imageHeight="300px"
                    marginTopContent="60px"
                    reverseDirection
                    horizontal
                    padding
                />
                        
                <Card
                    title="ID verification and Government database checks"
                    description="<p>Verify any identity document or government database checks in less than 1 minute: INE, Passport, RENAPO, SAT, and guarantee that your users are really citizens.</p>"
                    descriptionColor="#363636"
                    image="rem-3.png"
                    imageWidth="260px"
                    imageHeight="270px"
                    marginTopContent="60px"
                    horizontal
                    padding
                />        
                
                <Card
                    title="Advanced validations"
                    description="<p>OCR INE recognition, Proof of address: Telmex and CFE Proof of tax status, payroll receipts and invoices. Address and location validation, telephone and email verification, bank account validation and credit bureau checks.</p>"
                    descriptionColor="#363636"
                    image="rem-4.png"
                    imageWidth="260px"
                    imageHeight="230px"
                    marginTopContent="60px"
                    reverseDirection
                    horizontal
                    padding
                />
                        
                <Card
                    title="How it works"
                    description="<p>We have built state of the art proven identify workflows that you can plug in right into your platform and in a matter of days.</p>"
                    descriptionColor="#363636"
                    image="rem-1.png"
                    imageWidth="260px"
                    imageHeight="280px"
                    marginTopContent="60px"
                    horizontal
                    padding
                />
            </PageSection>
            <SecondarySection
                title="With EVVA REM"
                shortDescription="Drive business results with identity verification and authentication."
                position="center"
                actionLabel="Get in touch"
                action={launchFormHandler}
                amplitude={appState.amplitude}
                amplitudeClickEvent="ClickLearnEVVArem"
            >
                <div style={{margin: "auto auto 24px", maxWidth: "780px"}}>
                    <img src="../../rem-image.png" width="100%" alt="EVVA REM"/>
                </div>
            </SecondarySection>
        </Layout>
    )
};

export default RemIdentity;
